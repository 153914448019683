import React from 'react';
import { withRouter } from "react-router-dom";


function NotAuthPage (props) {
    
    const redirectToLogin = () => {
        props.history.push('/login'); 
    }

    return (
        <form>
            <div>
                <p>
                    <h3>Devi essere un utente registrato per poter vedere questa pagina!</h3>
                </p>
            </div>
            <div className="mt-2">
                <span>Sei un utente già registrato? Esegui il </span>
                <span className="loginText" onClick={() => redirectToLogin()}>Login</span> 
            </div>

        </form>
    )
    
}

export default withRouter(NotAuthPage);