import {API_BASE_URL, PLANET_KEY, COMPOSITE_KEY} from '../../../constants/apiContants';


var rp = require('request-promise').defaults({json: true});


const api_root_planets   = API_BASE_URL + 'getPlanetsData/'; 
const api_root_markets   = API_BASE_URL + 'getMarketData/tbl_';
const api_root_composite = API_BASE_URL + 'getCompositeData/';
const api_root_symbols_markets = API_BASE_URL + 'getSymbolsMarkets/';
const api_root_symbols_planets = API_BASE_URL + 'getSymbolsPlanets/';

const history = {};

export default {
	history: history,

    getBars: function(symbolInfo, resolution, from, to, first, limit) {
		//var split_symbol = symbolInfo.name.split(/[:/]/)
			/*
			const url = resolution === 'D' ? '/data/histoday' : resolution >= 60 ? '/data/histohour' : '/data/histominute'
			const qs = {
					e: split_symbol[0],
					fsym: split_symbol[1],
					tsym: split_symbol[2],
					toTs:  to ? to : '',
					limit: limit ? limit : 2000, 
					// aggregate: 1//resolution 
				}*/
			// console.log({qs})
			var symbName=symbolInfo.name;
			var uri=api_root_markets;
			if(symbolInfo.name.includes(PLANET_KEY)){
				var split_symbol = symbolInfo.name.split(/[:/]/);
				if(split_symbol.length>0){
					uri=api_root_planets;
					symbName=split_symbol[1];
				}
			} else if (symbolInfo.name.includes(COMPOSITE_KEY)){
				var split_symbol = symbolInfo.name.split(/[:/]/);
				if(split_symbol.length>0){
					uri=api_root_composite;
					symbName=split_symbol[1];
				}
			}

			var options = {
				uri: `${uri}` + symbName,
				/*
				qs: {
					access_token: 'xxxxx xxxxx' // -> uri + '?access_token=xxxxx%20xxxxx'
				},*/
				headers: {
					'Content-Type': 'application/json' , 
					'User-Agent': 'Request-Promise',
					'Access-Control-Request-Headers': 'Content-Type, Authorization',
					'Access-Control-Allow-Origin': '*'
				},
				resolveWithFullResponse: true,
				//simple: true,
				json: true // Automatically parses the JSON string in the response
			};
			return rp(options)
			.then(data => {
				//__dirname
                console.log({data})
				if (data.Response && data.Response === 'Error') {
					console.log('localDBProvider API error:',data.Message)
					return []
				}
				if (data.body.data.Data.length) {
					console.log(`Actually returned: ${new Date((data.body.data.TimeFrom)).toISOString()} - ${new Date((data.body.data.TimeTo)).toISOString()}`)
					var bars = data.body.data.Data.map(el => {
						return {
							time: el.time, //TradingView requires bar time in ms
							low: el.low,
							high: el.high,
							open: el.open,
							close: el.close,
							volume: el.volumefrom 
						}
					})
						if (first) {
							var lastBar = bars[bars.length - 1]
							history[symbolInfo.name] = {lastBar: lastBar}
						}
					return bars
				} else {
					return []
				}
			})
			.catch(console.error);
	},

	// chiede la lista dei simboli (mercati + pianeti)
    getSymbols: function(userInput) {
			var uri=api_root_symbols_markets + userInput;
			if(userInput.includes(PLANET_KEY)){
				var split_symbol = userInput.split(/[:/]/);
				uri=api_root_symbols_planets;
				if(split_symbol.length>0){
					uri=uri+split_symbol[1];
				}
			}
			console.log('--debug uri: ' + uri);
			var options = {
				uri: `${uri}`, //+ planetName,
				/*
				qs: {
					access_token: 'xxxxx xxxxx' // -> uri + '?access_token=xxxxx%20xxxxx'
				},*/
				headers: {
					'Content-Type': 'application/json' , 
					'User-Agent': 'Request-Promise',
					'Access-Control-Request-Headers': 'Content-Type, Authorization',
					'Access-Control-Allow-Origin': '*'
				},
				resolveWithFullResponse: true,
				//simple: true,
				json: true // Automatically parses the JSON string in the response
			};
			return rp(options)
			.then(data => {
				//__dirname
                //console.log('getSymbols data resp: ' + {data});
				if (data.Response && data.Response === 'Error') {
					console.log('localDBProvider API error:',data.Message)
					return []
				}
				if (data.body.data.Data.length) {
					var dataInfo = data.body.data.Data.map(el => {
						return {
							symbol: el.symbol, 
							full_name: el.full_name,
							description: el.description,
							exchange: el.exchange,
							ticker: el.ticker,
							type: el.type 
						}
					})
					return dataInfo
				} else {
					//console.log('error1111');
					return []
				}
			})
			.catch(console.error);
	}
	
	
}




