import React, {useState, Component} from 'react';
import './App.css';
import Header from './components/Header/Header';
import LoginForm from './components/LoginForm/LoginForm';
import RegistrationForm from './components/RegistrationForm/RegistrationForm';
import Home from './components/Home/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import AlertComponent from './components/AlertComponent/AlertComponent'; 
import NotAuthPage from './components/NotAuthPage/NotAuthPage';


function App(props) {
  const [title, updateTitle] = useState(null);
  const [errorMessage, updateErrorMessage] = useState(null);
  // Dichiara una nuova variable di stato, che chiameremo "userLogged"  
  const [userLogged, updateUserLogged] = useState(sessionStorage.getItem('userLogged') || null);
  
  
  return (
    <Router>
      <div className="App">
        <Header title={title}/>
          <div className="container-fluid align-items-center flex-column">

            <Switch>
              <Route path="/" exact={true}>
                <div className="hv-center">
                  <LoginForm updateUserLogged={updateUserLogged} showError={updateErrorMessage} updateTitle={updateTitle}/>
                </div>
              </Route>
              <Route path="/register">
                <div className="hv-center">
                  <RegistrationForm showError={updateErrorMessage} updateTitle={updateTitle}/>
                </div>
              </Route>
              <Route path="/login">
                <div className="hv-center">
                  <LoginForm updateUserLogged={updateUserLogged} showError={updateErrorMessage} updateTitle={updateTitle}/>
                </div>
              </Route>
              {userLogged ? 
                  <Route path="/GianTrader">
                    <Home/>
                  </Route>
                : <NotAuthPage/>
              }
            </Switch>

            <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage}/>

          </div>
      </div>
    </Router>
  );
}

export default App;




