import React from 'react';
import { TVChartContainer } from '../../components/TVChartContainer';
import './App.css'; 


/*
container d-flex align-items-center flex-column{
    disabilitare align-items: center!important;
};
*/


function Home(props) {    
    console.log('blaaaaaaaaablaaaaaa :' + props);
    return(
        //<div className="mt-2">
        //    Home page content
        //</div>
        <div className={ 'App' }>
            <TVChartContainer tvWidget={props.TVChartContainer}/>
        </div>
    )
}

export default Home;