//export const API_BASE_URL = 'http://localhost:5000/';   // versione per sviluppo locale

export const API_BASE_URL = 'https://giantrader.com/db-rest-service/';  // versione per deploy su a2hosting

//OLD...export const API_BASE_URL = 'https://db-rest-service.herokuapp.com/';  // versione per deploy su heroku


export const PLANET_KEY = 'CP:';      // chiave che identifica un ciclo planetario
export const COMPOSITE_KEY = 'COMP:'; // chiave che identifica un composite planetario

