import React, {useState} from 'react';
import axios from 'axios';
import './LoginForm.css';
import {API_BASE_URL} from '../../constants/apiContants';
import { withRouter } from "react-router-dom";



function sendEmailChangePwd(){
}


function LoginForm(props) {
    const [state , setState] = useState({
        email : "",
        password : "",
        successMessage: null
    })
    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }
    //console.log('userLogged in loginForm: ' + props.userLogged); 
    const handleSubmitClick = (e) => {
        e.preventDefault();
        const payload={
            "email":state.email,
            "password":state.password,
        }
        axios.post(API_BASE_URL+'verifyLogin', payload)
            .then(function (response) {
                if(response.data.code === 200){
                    setState(prevState => ({
                        ...prevState,
                        'successMessage' : 'Login ok. Redirect alla home page..'
                    }))
                    // salva lo user loggato nel sessionStorage
                    sessionStorage.setItem('userLogged', state.email);
                    props.showError(null);       
                    //console.log('userLogged after: ' + state.userLogged);    
                    props.updateUserLogged(1);  // setta flag utente loggato!        
                    redirectToHome();
                }
                else if(response.data.code === 204){
                    props.showError("Username e password non corrispondono");
                    props.updateUserLogged(0); // resetta flag utente loggato!
                }
                else{
                    props.showError("Username non esistente");
                    props.updateUserLogged(0); // resetta flag utente loggato!
                }
            })
            .catch(function (error) {
                console.log(error);
                props.updateUserLogged(0); // resetta flag utente loggato!
            });
    }
    const redirectToHome = () => {
        props.updateTitle('GianTrader');
        props.history.push('/GianTrader');
    }
    const redirectToRegister = () => {

        props.history.push('/register'); 
        props.updateTitle('Register');
    }
    const sendPwdChangeRequest = () => {
        sendEmailChangePwd();
    }
    return(
        <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
            <form>
                <div className="form-group text-left">
                <label htmlFor="exampleInputEmail1">Indirizzo email</label>
                <input type="email" 
                       className="form-control" 
                       id="email" 
                       aria-describedby="emailHelp" 
                       placeholder="Inserire email" 
                       value={state.email}
                       onChange={handleChange}
                />
                <small id="emailHelp" className="form-text text-muted">I dati inseriti non verranno diffusi a terzi</small>
                </div>
                <div className="form-group text-left">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input type="password" 
                       className="form-control" 
                       id="password" 
                       placeholder="Password"
                       value={state.password}
                       onChange={handleChange} 
                />
                </div>
                <div className="form-check">
                </div>
                <button 
                    type="submit" 
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                >Entra</button>
            </form>
            <div className="alert alert-success mt-2" style={{display: state.successMessage ? 'block' : 'none' }} role="alert">
                {state.successMessage}
            </div>
            <div className="registerMessage">
                <span>Non hai un account? </span>
                <span className="loginText" onClick={() => redirectToRegister()}>Register</span> 
            </div>
            <div className="pwdChangeMessage">
                <span>Richiesta </span>
                <span className="loginText" onClick={() => sendPwdChangeRequest()}>cambio password</span> 
            </div>
        </div>
    )
}

export default withRouter(LoginForm);