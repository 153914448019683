import React, { useEffect, useState, useLayoutEffect }  from 'react';
//import './CyclesSelector.css';
 

const CyclesSelector = (props) => {
    let cycles_selected = props.cycles_selected;
    const [memo_cycles_selected, setmemo_cycles_selected] = useState(props.cycles_selected);

    // CkCheckbox component
    function handleChange(e) {
        //console.log(e.target.value);
        if(!cycles_selected){
            cycles_selected=[];
        }
        //console.log(e.target.id + ":" + e.target.checked);
        // inserisce ciclo selezionato
        if(e.target.checked){
            if(!cycles_selected.includes(e.target.id)){
                cycles_selected.push(e.target.id);
            }
        // toglie ciclo deselezionato
        } else {
            if(cycles_selected.includes(e.target.id)){
                cycles_selected.splice(cycles_selected.indexOf(e.target.id), 1);
            }
        }
        setmemo_cycles_selected(cycles_selected);
        // ritorna al padre l'elenco dei cicli selezionati 
        props.sendCiclesSelected(cycles_selected);
    }

    function setInitialValue(){
        console.log("cicli presenti nel grafico: " + cycles_selected);
        // deseleziona il check-box degli eventuali cicli rimasti selezionati ma non presenti nel grafico (...tolti con x dall'utente)
        /*
        console.log("selezione cicli impostata nei check-boxes: " + memo_cycles_selected);
        memo_cycles_selected.forEach(element => {
            if(!cycles_selected.includes(element)){
                console.log("Deselezione del check " + element + " perchè rimosso nel grafio dall'utente(x)" );
                const c=document.getElementById(element);
                if(c){
                    c.checked=false;
                }
                const index = cycles_selected.indexOf(element);
                if (index > -1) {
                    cycles_selected.splice(index, 1);
                }

            }
        });
        */
       
        // imposta a true i combo dei cicli già presenti nel grafico
        cycles_selected.forEach(element => {
            //console.log(element);
            //document.getElementById("cb_luna-sole").setAttribute("checked", "true");
            //document.getElementById("myCheck").checked = true;
            const c=document.getElementById(element);
            if(c){
                c.checked=true;
            }
        });
    }

    //console.log("This happens on EVERY render.");
    setInitialValue();

    return <div>
        {
            <table class="table thead-dark table-bordered table-striped table-hover">
                <thead >
                    <tr>
                        <th>LUNA</th>
                        <th>SOLE</th>
                        <th>MERCURIO</th>
                        <th>VENERE</th>
                        <th>MARTE</th>
                        <th>GIOVE</th>
                        <th>SATURNO</th>
                        <th>URANO</th>
                        <th>NETTUNO</th>
                    </tr>
                </thead>

                <tbody >
                    <tr >
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_luna-sole" onChange={ handleChange }/>
                            <label for="cb_luna-sole" class="label-table form-check-label">Sole</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_sole-marte" onChange={ handleChange } />
                            <label for="cb_sole-marte" class="label-table form-check-label">Marte</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_mercurio-marte" onChange={ handleChange }/>
                            <label for="cb_mercurio-marte" class="label-table form-check-label">Marte</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_venere-marte" onChange={ handleChange }/>
                            <label for="cb_venere-marte" class="label-table form-check-label">Marte</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_marte-giove" onChange={ handleChange }/>
                            <label for="cb_marte-giove" class="label-table form-check-label">Giove</label>
                        </td>                                 
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_giove-saturno" onChange={ handleChange }/>
                            <label for="cb_giove-saturno" class="label-table form-check-label">Saturno</label>
                        </td>                                 
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_saturno-urano" onChange={ handleChange }/>
                            <label for="cb_saturno-urano" class="label-table form-check-label">Urano</label>
                        </td>                                 
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_urano-nettuno" onChange={ handleChange }/>
                            <label for="cb_urano-nettuno" class="label-table form-check-label">Nettuno</label>
                        </td>                                 
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_nettuno-plutone" onChange={ handleChange }/>
                            <label for="cb_nettuno-nettuno" class="label-table form-check-label">Plutone</label>
                        </td>                                 
                    </tr>
                    <tr>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_luna-mercurio" onChange={ handleChange }/>
                            <label for="cb_luna-mercurio" class="label-table form-check-label">Mercurio</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_sole-giove" onChange={ handleChange }/>
                            <label for="cb_sole-giove" class="label-table form-check-label">Giove</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_mercurio-giove" onChange={ handleChange }/>
                            <label for="cb_mercurio-giove" class="label-table form-check-label">Giove</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_venere-giove" onChange={ handleChange }/>
                            <label for="cb_venere-giove" class="label-table form-check-label">Giove</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_marte-saturno" onChange={ handleChange }/>
                            <label for="cb_marte-saturno" class="label-table form-check-label">Saturno</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_giove-urano" onChange={ handleChange }/>
                            <label for="cb_giove-urano" class="label-table form-check-label">Urano</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_saturno-nettuno" onChange={ handleChange }/>
                            <label for="cb_saturno-nettuno" class="label-table form-check-label">Nettuno</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_urano-plutone" onChange={ handleChange }/>
                            <label for="cb_urano-plutone" class="label-table form-check-label">Plutone</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_luna-venere" onChange={ handleChange }/>
                            <label for="cb_luna-venere" class="label-table form-check-label">Venere</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_sole-saturno" onChange={ handleChange }/>
                            <label for="cb_sole-saturno" class="label-table form-check-label">Saturno</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_mercurio-saturno" onChange={ handleChange }/>
                            <label for="cb_mercurio-saturno" class="label-table form-check-label">Saturno</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_venere-saturno" onChange={ handleChange }/>
                            <label for="cb_venere-saturno" class="label-table form-check-label">Saturno</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_marte-urano" onChange={ handleChange }/>
                            <label for="cb_marte-urano" class="label-table form-check-label">Urano</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_giove-nettuno" onChange={ handleChange }/>
                            <label for="cb_giove-nettuno" class="label-table form-check-label">Nettuno</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_saturno-plutone" onChange={ handleChange }/>
                            <label for="cb_saturno-plutone" class="label-table form-check-label">Plutone</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_luna-marte" onChange={ handleChange }/>
                            <label for="cb_luna-marte" class="label-table form-check-label">Marte</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_sole-urano" onChange={ handleChange }/>
                            <label for="cb_sole-urano" class="label-table form-check-label">Urano</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_mercurio-urano" onChange={ handleChange }/>
                            <label for="cb_mercurio-urano" class="label-table form-check-label">Urano</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_venere-urano" onChange={ handleChange }/>
                            <label for="cb_venere-urano" class="label-table form-check-label">Urano</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_marte-nettuno" onChange={ handleChange }/>
                            <label for="cb_marte-nettuno" class="label-table form-check-label">Nettuno</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_giove-plutone" onChange={ handleChange }/>
                            <label for="cb_giove-plutone" class="label-table form-check-label">Plutone</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_luna-giove" onChange={ handleChange }/>
                            <label for="cb_luna-giove" class="label-table form-check-label">Giove</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_sole-nettuno" onChange={ handleChange }/>
                            <label for="cb_sole-nettuno" class="label-table form-check-label">Nettuno</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_mercurio-nettuno" onChange={ handleChange }/>
                            <label for="cb_mercurio-nettuno" class="label-table form-check-label">Nettuno</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_venere-nettuno" onChange={ handleChange }/>
                            <label for="cb_venere-nettuno" class="label-table form-check-label">Nettuno</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_marte-plutone" onChange={ handleChange }/>
                            <label for="cb_marte-plutone" class="label-table form-check-label">Plutone</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_luna-saturno" onChange={ handleChange }/>
                            <label for="cb_luna-saturno" class="label-table form-check-label">Saturno</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_sole-plutone" onChange={ handleChange }/>
                            <label for="cb_sole-plutone" class="label-table form-check-label">Plutone</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_mercurio-plutone" onChange={ handleChange }/>
                            <label for="cb_mercurio-plutone" class="label-table form-check-label">Plutone</label>
                        </td>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_venere-plutone" onChange={ handleChange }/>
                            <label for="cb_venere-plutone" class="label-table form-check-label">Plutone</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_luna-urano" onChange={ handleChange }/>
                            <label for="cb_luna-urano" class="label-table form-check-label">Urano</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_luna-nettuno" onChange={ handleChange }/>
                            <label for="cb_luna-nettuno" class="label-table form-check-label">Nettuno</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input class="form-check-input" type="checkbox" id="cb_luna-plutone" onChange={ handleChange }/>
                            <label for="cb_luna-plutone" class="label-table form-check-label">Plutone</label>
                        </td>
                    </tr>
                </tbody>

            </table>
        }
    </div>
}

export default CyclesSelector;
