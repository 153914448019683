//import * as React from 'react';
import React, { Component, PropTypes } from 'react';
import './index.css';
import Datafeed from './api/'
import { API_BASE_URL } from '../../constants/apiContants';
import CyclesSelector from '../Widgets/CyclesSelector';
import {widget} from '../../charting_library/charting_library';
import $ from 'jquery';
//import { debug } from 'request-promise';

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

var myTwWidget=null;
var cycles_selected=[];
var cycles_loaded = [];
var cycles_chart = [];  
var needChartZoomReposition=false;
var position_form=0;
var position_to=0;
var currentChartSaved=null;


function getCyclesLoaded(){
	const cycles_plotted=myTwWidget.chart().getAllStudies();
	let cycles=[];
	cycles_chart=[];
	cycles_plotted.forEach(element => {
		let simbolo = myTwWidget.chart().getStudyById(element.id)._study._legendView._header;
		simbolo=simbolo.replace("CP:","cb_").toLowerCase();
		cycles.push(simbolo);
		// costruisce anche l'oggetto cycles_chart
		cycles_chart.push({'id' : element.id, 'simbolo' : simbolo});
	});	
	return(cycles);
}

// --- INIZIO: metodi per gestione posizione zoom su grafico ---


// metodo per caricare da locastorage e impostare l'ultima posizione di zoom salvata per il grafico corrente
function loadPositionCurrentChart () {
	if(needChartZoomReposition){
		// se esiste nel locastorage l'impostazione dello zoom salvata per questo studio la ripristina
		let chartZoomData=localStorage.getItem("chartZoomData");
		let chartSaved=getCurrentChartLoaded();
		if(chartZoomData){
			chartZoomData=JSON.parse(chartZoomData);
			if(chartZoomData){
				for(let i=0;i<chartZoomData.length;i++){
					if(chartZoomData[i].chart_name==chartSaved){
						if(chartZoomData[i].from && chartZoomData[i].to){
							needChartZoomReposition=false;
							myTwWidget.activeChart().setVisibleRange(
								{ from: chartZoomData[i].from, to: chartZoomData[i].to },
								{ percentRightMargin: 20 }
							).then(() => console.log('New visible range is applied of ' + chartSaved));
						}		
					}
				}	
			}
		}
	}		
}

// metodo per salvare su locastorage l'ultima posizione di zoom del grafico corrente
function savePositionCurrentChart (from, to) {
	let chartZoomData=localStorage.getItem("chartZoomData");
	let chartSaved=getCurrentChartLoaded();
	if(chartZoomData==null){
		// primo salvataggio
		chartZoomData=[];
		let newData={"chart_name" : chartSaved, "from" : from, "to" : to};
		chartZoomData.push(newData);
		localStorage.setItem("chartZoomData", JSON.stringify(chartZoomData));
	} else {
		// aggiornamento salvataggio
		chartZoomData=JSON.parse(localStorage.getItem("chartZoomData"));
		let update=-1;
		for(let i=0;i<chartZoomData.length;i++){
			//console.log('chart_name: ' + chartZoomData[i].chart_name);
			//console.log('from: ' + chartZoomData[i].from);
			//console.log('to: ' + chartZoomData[i].to);
			if(chartZoomData[i].chart_name==chartSaved){
				// flag update
				update=i;
			}
		}
		if(update>-1){
			// update
			chartZoomData[update].from=from;
			chartZoomData[update].to=to;
			localStorage.setItem("chartZoomData", JSON.stringify(chartZoomData));	
		} else {
			// new insert
			let newData={"chart_name" : chartSaved, "from" : from, "to" : to};
			chartZoomData.push(newData);
			localStorage.setItem("chartZoomData", JSON.stringify(chartZoomData));	
		}
		
	}
}

// restituisce il nome del grafico caricato 
function getCurrentChartLoaded(){
	let ret="";
	const currentStudy=$('#tv_chart_container > iframe').contents().find("div");
	for(let element of currentStudy){
		if(element.className=='js-button-text text-1sK7vbvh text-1HWeUAzA'){
			ret=element.childNodes[0].data;	
		}
	};
	//console.log('Study ' + currentStudy + ' has loaded!');
	return(ret);
}

function attachSaveChartEvent(){
	// agganciare evento al click in un div
	const d=$('#tv_chart_container > iframe').contents().find("div");
/*
	$(d[183]).click(function() {
		savePositionCurrentChart(position_form, position_to);
		console.log("[276] chart position has saved !"); //alert( "chart position has saved 276!" );
	});
*/

	for(let element of d){
		if(element.id=='header-toolbar-save-load'){
			$(element).click(function() {
				savePositionCurrentChart(position_form, position_to);
				console.log("chart position has saved !"); //alert( "chart position has saved 276!" );
			});
		}
	};


	
	/*
	$(d[277]).click(function() {
		savePositionCurrentChart(position_form, position_to);
		console.log("[277] chart position has saved !"); //alert( "chart position has saved 277!" );
	});
	$(d[278]).click(function() {
		savePositionCurrentChart(position_form, position_to);
		console.log("[278] chart position has saved !"); //alert( "chart position has saved 278!" );
	});
	*/
}
// --- FINE: metodi per salvataggio posizione zoom su grafico ---




// finestra modale
const Modal = ({ handleClose, handleHide, show, children }) => {
	const showHideClassName = show ? "modal display-block" : "modal display-none";

	//recupero lista cicli planetari
	if(myTwWidget){ // serve perchè all'avvio è null
		cycles_loaded=getCyclesLoaded();
	//console.log("elenco cicli: " + cycles_loaded);	
	}
	//recupero simbolo (ciclo planetario) partendo dall'id dello studio 
	//tvWidget.chart().getStudyById("1r8NEO")._study._inputs.symbol;

	function sendCiclesSelected (cicles) { // la callback da Cycles Selector che aggiorna la lista dei cicli selezionati
		//console.log("_Elenco cicli selezionati: " + cicles);
		cycles_selected=cicles;
	};
	/*
	if(handleClose){ // evento di chiusura della modale
		console.log("handle close");
	}
	*/

	return (
		<div className={showHideClassName}>
			<section className="modal-main">
				{children}
				<CyclesSelector cycles_selected={cycles_loaded} sendCiclesSelected={sendCiclesSelected} />
				<button onClick={handleClose}>Conferma</button>
				<button onClick={handleHide}>Annulla</button>
			</section>
		</div>
	);
};


export class TVChartContainer extends React.PureComponent {
	static defaultProps = {
		symbol: '',
		interval: 'D',
		containerId: 'tv_chart_container',
		libraryPath: '/charting_library/',
		chartsStorageUrl: API_BASE_URL + 'LoadSaveCharts',  //'http://localhost:5000/LoadSaveCharts', // 'https://db-rest-service.herokuapp.com/LoadSaveCharts', //'https://saveload.tradingview.com',   // 'http://localhost:5000/LoadSaveCharts'
		chartsStorageApiVersion: '1.1',
		clientId: 'giantrader',
		userId: sessionStorage.getItem('userLogged'), //'public_user_id', 
		fullscreen: false,
		autosize: true,
		loadLastChart: true,
		studiesOverrides: {},
		debug: false,
		snapshot_url: API_BASE_URL + 'snapshot'
	};

	
	// carica/elimina i cicli selezionati nella modale
	loadCicli = () => {
		console.log(".....loadCicli!!");
		//console.log("elenco cicli selezionati: " + cycles_selected);
		
		//recupera i cicli già presenti nel grafico
		cycles_loaded=getCyclesLoaded();

		if(cycles_loaded.length || cycles_selected.length){
			// trova l'id del primo studio inserito nel secondo asse Y
			let studies=myTwWidget.chart().getAllStudies();
			let idStudyRif=null;
			if(studies.length>0){
				if(studies[0].name!="Volume"){
					idStudyRif=studies[0].id;
				}
			}		

			// controlla se ci sono cicli da eliminare
			cycles_loaded.forEach(element => {
				if(!cycles_selected.includes(element)){
					console.log('<<<< --- rimozione ciclo:  ' + element);
					const el=cycles_chart.find(item=>item.simbolo==element);
					myTwWidget.chart().removeEntity(el.id);
				};				 
			});

			// controlla se ci sono nuovi cicli da inserire
			cycles_selected.forEach(element => {
				if(!cycles_loaded.includes(element)){
					element=element.replace("cb_","").toLowerCase();
					console.log('<<<< --- inserisco ciclo:  ' + element);
					myTwWidget.chart().createStudy('Compare', false, false, ["close", 'CP:' + element]).then(id => {
						if (id) {
							if(idStudyRif!=null){
								console.log("id studio rif. : " + idStudyRif);
								myTwWidget.chart().getStudyById(id).changePriceScale(idStudyRif);
							} else {
								myTwWidget.chart().getStudyById(id).changePriceScale('new-left');
								idStudyRif=id;	
							}
							console.log("Inserito studio con id: " + id);
							// imposta la scala dei due assi Y come normal
							myTwWidget.activeChart().getPanes()[0].getLeftPriceScales()[0].setMode(0);
							myTwWidget.activeChart().getPanes()[0].getRightPriceScales()[0].setMode(0);
						}
					});				
				}
			});
			
		}

	};

	// -- INIZIO: usate dalla modale --
	state = { show: false };

	showModal = () => {
		this.setState({ show: true });
	};

	closeModal = () => {
		this.setState({ show: false });
		this.loadCicli();
	};

	hideModal = () => {
		this.setState({ show: false });
	};

	// -- FINE: usate dalla modale --

	// -- INIZIO: gestione checkboxes interni alla modale dei cicli --

	handleFormSubmit = (customData) => (formSubmitEvent, useThis) => {
	//handleFormSubmit = (formSubmitEvent, useThis) => {
		formSubmitEvent.preventDefault();

		//console.log("deb..." + customData.useThis);
		console.log("deb 2: " + myTwWidget);
		myTwWidget.chart().createStudy('Compare', false, false, ["close", 'CP:Giove-Nettuno']);
	}

	// -- FINE: gestione checkboxes interni alla modale dei cicli --

	tvWidget = null;

	componentDidMount() {
		needChartZoomReposition=false; // reset flag 
		const widgetOptions = {
			debug: this.props.debug,
			symbol: this.props.symbol,
			datafeed: Datafeed,
			interval: this.props.interval,
			container_id: this.props.containerId,
			library_path: this.props.libraryPath,
			locale: 'it',
			supported_resolutions: ['D', 'W'], // 'M'],
			//disabled_features: ['compare_symbol','display_market_status'],
			charts_storage_url: this.props.chartsStorageUrl,
			charts_storage_api_version: this.props.chartsStorageApiVersion,
			client_id: this.props.clientId,
			user_id: sessionStorage.getItem('userLogged'), // this.props.userId,
			load_last_chart: this.props.loadLastChart,
			fullscreen: this.props.fullscreen,
			snapshot_url: this.props.snapshot_url,
			autosize: this.props.autosize,
			studies_overrides: this.props.studiesOverrides,
			disabled_features: ["widget_logo","display_market_status", "order_panel", "pricescale_currency", "study_templates", "header_compare"],
			enabled_features: ["use_localstorage_for_settings"],
			overrides: {
				// "mainSeriesProperties.showCountdown": true,
				"paneProperties.background": "#131722",
				"paneProperties.vertGridProperties.color": "#363c4e",
				"paneProperties.horzGridProperties.color": "#363c4e",
				"symbolWatermarkProperties.transparency": 90,
				"scalesProperties.textColor": "#AAA",
				// Series style. See the supported values below: Bars = 0, Candles = 1, Line = 2, Area = 3, Heikin Ashi = 8, Hollow Candles = 9, Renko = 4, Kagi = 5, Point&Figure = 6, Line Break = 7, Baseline = 10
				"mainSeriesProperties.style": 2,
				//"mainSeriesProperties.candleStyle.wickUpColor": '#336854',
				//"mainSeriesProperties.candleStyle.wickDownColor": '#7f323f',
				//"Overlay.style": 1,
				//"Overlay.lineStyle.linewidth": 1,
				"Overlay.lineStyle.priceSource": "close",
				//"mainSeriesProperties.lineStyle.linestyle": "LINESTYLE_SOLID",
				//"mainSeriesProperties.lineStyle.linewidth": 3,
				"mainSeriesProperties.lineStyle.priceSource": "close"
			},
			/*	
				custom_indicators_getter: function(PineJS) {
					return Promise.resolve([
						// *** your indicator object, created from the template ***
						__customIndicators
					]);
				}
			*/

			/*
			custom_indicators_getter: function (PineJS) {
				return Promise.resolve([
					Raffo1(PineJS, "PLANET:MARTE-SATURNO", "cp_marte-saturno"),
					Raffo1(PineJS, "PLANET:MARTE-URANO", "cp_marte-urano")
				]);
			}
			*/
		};

		// const tvWidget = window.tvWidget = myTwWidget = new window.TradingView.widget(widgetOptions);
		const tvWidget = new widget(widgetOptions); 
		this.tvWidget = tvWidget;
		myTwWidget = tvWidget;

		myTwWidget.onChartReady(() => {	
			
			//myTwWidget.headerReady().then(() => {
			//console.log('Chart has loaded!');
			currentChartSaved=getCurrentChartLoaded();
			console.log('Chart ' + currentChartSaved + ' has loaded!');
			attachSaveChartEvent();
			loadPositionCurrentChart();
			myTwWidget.activeChart().dataReady(
				null,
				() =>{
					console.log('New history bars are loaded');
					needChartZoomReposition=true;
					//loadPositionCurrentChart();
					/*
					const from=Date.now()/1000 - 500*24*3600;
					const to=Date.now()/1000;
					myTwWidget.activeChart().createShape(
						[{time: to, price: 25000}], {shape: 'horizontal_line'}
					);
					*/
				},
				true
			);

			/*
			myTwWidget.getSavedCharts(function(objects) {  
				// ordinamento decrescente in base alla data di modifica
				objects.sort(function(x, y){
					return y.modified_iso - x.modified_iso;
				});
				
				//objects.forEach(chart => {
				//	console.log("CallBack getSavedCharts " + chart.id + " - " + chart.name + " - " + chart.modified_iso + ";");				
				//});
				
				console.log("caricato chart: " + objects[0].id + " - " + objects[0].name + " - " + objects[0].modified_iso + ";");				
				chartSaved=objects[0];
				if(needChartZoomReposition){
					loadPositionCurrentChart(chartSaved);
				}
				// 
			});
			*/

			// sottoscrive evento di aggiornamento della posizione visualizzata
			myTwWidget.activeChart().onVisibleRangeChanged().subscribe(
				null, ({ from, to }) => {
					if(currentChartSaved){
						//savePositionCurrentChart(from, to, currentChartSaved);
						position_form=from;
						position_to=to;
					}
				}
			);

			myTwWidget.activeChart().onDataLoaded().subscribe(
				null, () => {				
					loadPositionCurrentChart();
					console.log('onDataLoaded().subscribe .... ' + myTwWidget.activeChart().dataReady());
				}
			);

		/*
				widget.onChartReady(() => {
					console.log('Chart has loaded!');
					//console.log(widget.getStudiesList());
					//widget.chart().createStudy('Compare', false, false, ['close', 'PLANET:MARTE-GIOVE']);
					//widget.chart().createStudy('Raffo1', false, true);
					//widget.chart().onSymbolChanged().subscribe(null, function(symbolData){
					//	console.log("debug new symbol: " + symbolData);
					//});
		/*
					// save reference to the chart object
					this.chartRef = widget.chart();
		
					this.chartRef.createStudy('myStudyName', false, true).then(id => {
					if (id) {
						this.chartRef && this.chartRef.getStudyById(id).changePriceScale('left');
					}
					});
		
		
				});	
		*/
		});

		myTwWidget.headerReady().then(() => {
			const button = tvWidget.createButton();
			button.setAttribute('title', 'Clicca per aggiungere i cicli planetari');
			button.classList.add('apply-common-tooltip');
			/*
						button.addEventListener('click', () => tvWidget.activeChart().executeActionById("symbolSearch"));
						button.setAttribute('id', 'custom_search');
			*/
			button.addEventListener('click', () => {
				this.showModal();
				//loadPositionCurrentChart();
			});

			button.textContent = 'cicli';
			/*			
			button.addEventListener('click', () => tvWidget.showNoticeDialog({
				title: 'Cicli planetari',
				body: 'Seleziona quali cicli visualizzare...',
				callback: () => {
					console.log('Noticed!');
					tvWidget.chart().createStudy('Compare', false, false, ["close", 'CP:Giove-Nettuno']);
				},
			}));
			button.innerHTML = 'cicli';
			*/
			//var buttonSave=$('#tv_chart_container > iframe').contents().find("div")[277].childNodes[0].data;
			//console.log('BYE');
		});

	}

	componentWillUnmount() {
		if (this.tvWidget != null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	render() {
		return (
			<main>
				<Modal show={this.state.show} handleClose={this.closeModal} handleHide={this.hideModal}>
				</Modal>
				<div
					id={this.props.containerId}
					className={'TVChartContainer'}
				/>
			</main>
		);
	}

}


// test composite cicli di marte: 
// COMP:MARTE-GIOVE;MARTE-SATURNO;MARTE-URANO;MARTE-NETTUNO;MARTE-PLUTONE;
// COMP:Giove-Saturno;Giove-Urano;Giove-Nettuno;Giove-Plutone;Saturno-Urano;Saturno-Nettuno;Saturno-Plutone;Urano-Nettuno;Urano-Plutone;Nettuno-Plutone
// COMP:MARTE-GIOVE;MARTE-SATURNO;MARTE-URANO;MARTE-NETTUNO;MARTE-PLUTONE;Giove-Saturno;Giove-Urano;Giove-Nettuno;Giove-Plutone;Saturno-Urano;Saturno-Nettuno;Saturno-Plutone;Urano-Nettuno;Urano-Plutone;Nettuno-Plutone
// COMP:VENERE-MARTE;VENERE-GIOVE;VENERE-SATURNO;VENERE-URANO;VENERE-NETTUNO;VENERE-PLUTONE;MARTE-GIOVE;MARTE-SATURNO;MARTE-URANO;MARTE-NETTUNO;MARTE-PLUTONE;Giove-Saturno;Giove-Urano;Giove-Nettuno;Giove-Plutone;Saturno-Urano;Saturno-Nettuno;Saturno-Plutone;Urano-Nettuno;Urano-Plutone;Nettuno-Plutone
// COMP:SOLE-MARTE;SOLE-GIOVE;SOLE-SATURNO;SOLE-URANO;SOLE-NETTUNO;SOLE-PLUTONE;VENERE-MARTE;VENERE-GIOVE;VENERE-SATURNO;VENERE-URANO;VENERE-NETTUNO;VENERE-PLUTONE;MARTE-GIOVE;MARTE-SATURNO;MARTE-URANO;MARTE-NETTUNO;MARTE-PLUTONE;Giove-Saturno;Giove-Urano;Giove-Nettuno;Giove-Plutone;Saturno-Urano;Saturno-Nettuno;Saturno-Plutone;Urano-Nettuno;Urano-Plutone;Nettuno-Plutone