import React, { useState } from 'react';
import axios from 'axios';
import './RegistrationForm.css';
import { API_BASE_URL } from '../../constants/apiContants';
import { withRouter } from "react-router-dom";

function RegistrationForm(props) {
    const [state, setState] = useState({
        name: "",
        surname: "",
        telephone: "",
        email: "",
        password: "",
        confirmPassword: "",
        successMessage: null
    })
    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }
    const sendDetailsToServer = () => {
        if (state.email.length && (state.password.length>=8) && state.name.length && state.surname.length) {
            props.showError(null);
            const payload = {
                "email": state.email,
                "password": state.password,
                "name": state.name,
                "surname": state.surname,
                "telephone": state.telephone
            }
            axios.post(API_BASE_URL + 'register', payload)
                .then(function (response) {
                    if (response.data.code === 200) {
                        setState(prevState => ({
                            ...prevState,
                            'successMessage': 'Registrazione completata. Ritorno alla home page..'
                        }))
                        redirectToLogin();
                        props.showError(null);
                    } else if (response.data.code === 202) {
                        console.log('resp_log: ' + response.data.message);
                        props.showError(response.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            if (!state.password.length){
                props.showError('Inserire una password valida');
            } else {
                if (state.password.length<8) props.showError('Inserire una password di almeno 8 caratteri');
            }
            if (!state.email.length) props.showError('Inserire una email valida');
            if (!state.surname.length) props.showError('Inserire un cognome valido');
            if (!state.name.length) props.showError('Inserire un nome valido');
            if (!state.password.length) props.showError('Inserire una password valida');
        }

    }
    const redirectToHome = () => {
        props.updateTitle('Home')
        props.history.push('/home');
    }
    const redirectToLogin = () => {
        props.updateTitle('Login')
        props.history.push('/login');
    }
    const handleSubmitClick = (e) => {
        e.preventDefault();
        if (state.password === state.confirmPassword) {
            sendDetailsToServer()
        } else {
            props.showError('Le password non corrispondono');
        }
    }
    return (
        <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
            <form>
                <div className="form-group text-left">
                    <div>
                        <label id="infoRegUser">La registrazione attiva in automatico una prova gratuita di 10 giorni! Inserire i propri dati, premere il pulsante Register, e poi nella pagina di login inserire email e password per accedere!</label>
                    </div>
                    <label htmlFor="inputName">Nome</label>
                    <input type="text"
                        className="form-control"
                        id="name"
                        placeholder="Inserire il proprio nome"
                        value={state.name}
                        required="true"
                        onChange={handleChange}
                    />
                    <label htmlFor="inputSurname">Cognome</label>
                    <input type="text"
                        className="form-control"
                        id="surname"
                        placeholder="Inserire il proprio cognome"
                        value={state.surname}
                        required="true"
                        onChange={handleChange}
                    />
                    <label htmlFor="inputTel">Telefono</label>
                    <input type="text"
                        className="form-control"
                        id="telephone"
                        placeholder="Inserire il proprio n° telefonico"
                        value={state.telephone}
                        onChange={handleChange}
                    />
                    <label htmlFor="exampleInputEmail1">Indirizzo email</label>
                    <input type="email"
                        className="form-control"
                        id="email"
                        required="true"
                        aria-describedby="emailHelp"
                        placeholder="Inserire l'indirizzo email"
                        value={state.email}
                        onChange={handleChange}
                    />
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input type="password"
                        className="form-control"
                        id="password"
                        required="true"
                        placeholder="Inserire la password"
                        value={state.password}
                        onChange={handleChange}
                    />
                    <label htmlFor="exampleInputPassword1">Conferma password</label>
                    <input type="password"
                        className="form-control"
                        id="confirmPassword"
                        placeholder="Confermare la password"
                        required="true"
                        value={state.confirmPassword}
                        onChange={handleChange}
                    />
                    <small id="emailHelp" className="form-text text-muted">I dati inseriti non verranno diffusi a terzi</small>
                </div>
                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                >
                    Register
                </button>
            </form>
            <div className="alert alert-success mt-2" style={{ display: state.successMessage ? 'block' : 'none' }} role="alert">
                {state.successMessage}
            </div>
            <div className="mt-2">
                <span>Hai già un account? </span>
                <span className="loginText" onClick={() => redirectToLogin()}>Login</span>
                <p></p>
                <span>Per l'acquisto </span>
                <a className="loginText" href="https://giancarloanelli.systeme.io/giantrader-coaching">Clicca qui</a> 

            </div>

        </div>
    )
}

export default withRouter(RegistrationForm);